html,
body {
  /* height: 100%; */
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #e5e5e5;
  font-family: IranYekanRegular;
}

.Toastify__toast-body {
  font-family: IranYekanRegular;
  z-index: 13000;
}
/* for upload file dialog in chat page */

.dialog-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content:center;
  align-items: center;
}

.dialog {
  width: 500px;
  background-color: white;
  display: flex;
  align-items:  center;
  flex-direction: column;
}

.dialog-form {
  width: 100%;
  margin-bottom: 0;
  padding: 20px;
}

.dialog-form > * {
  display: block;
}

.dialog-label {
  text-align: right;
  font-size: 16px;
}

.dialog-input, .username-input {
  width: 100%;
}

.dialog-header {
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.dialog-header svg {
  width: 36px;
  height: 36px;
}

.dialog-header button {
  border: none;
  width: auto;
  padding-right: 10px;
}

input[type="text"]:focus {
  border-color: #5C8436;
}

.submit-btn {
  color: #5C8436;
  background-color: #181919;
  width: 100%;
}

.submit-btn:hover {
  color: #5C8436;
  background-color: #222;
}
/* end of upload file dialog in chat page */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.message-input {
  width: 100%;
  margin-bottom: 0;
  height: 50px;
  flex-grow: 1;
  line-height: 35px;
  padding-left: 20px;
  border-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  font-size: 16px;
  color: #333;
  min-height: auto;
  overflow-y: hidden;
  border-left: 1px solid #ccc;
}
/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.css-1s2u09g-control {
border: none;
}
/* for whiteboard */
#circularcursor {
  background-color: transparent;
  border:1px solid black;    
  height:25px;
  width:25px;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  position: absolute;
  z-index: 1;
}

.canvas-btn {
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border-style: solid;
  padding: 5px 5px;
  border-color: gray;
  border-width: thin;
}

.btn-width {
  min-width: 50px;
}

.mouse-state {
  cursor: none;
}
/* for whiteboard */
/*.rmdp-input {
   width: 100%;
  height: 42px!important;
  border: none !important;
  margin: 0!important;
   margin-left:2.5%!important;  
   /* display: flex;
   justify-content: center;
   align-items: center; 
}*/
.rmdp-day-picker {
  background-color: white;
}
.rmdp-container {
  overflow-x: hidden !important;
  border: none !important;
}
.rmdp-input {
  width: 100% !important;
  border: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.rc-anchor {
  width: 80px !important;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
.bounce {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.fade {
  animation: fade 0.5s linear forwards;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
@font-face {
  font-family: IranYekanBlack;
  src: url("Fonts/iranyekanwebblackfanum.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: IranYekanExtraBlack;
  src: url("Fonts/iranyekanwebextrablackfanum.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: IranYekanBold;
  src: url("Fonts/iranyekanwebboldfanum.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: IranYekanExtraBold;
  src: url("Fonts/iranyekanwebextraboldfanum.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: IranYekanLight;
  src: url("Fonts/iranyekanweblightfanum.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: IranYekanThin;
  src: url("Fonts/iranyekanwebthinfanum.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: IranYekanRegular;
  src: url("Fonts/iranyekanwebregularfanum.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: IranYekanMedium;
  src: url("Fonts/iranyekanwebmediumfanum.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    height: 16px;
  }
  /* .scrollbar {
	  height: 16px;
	} */

  .scrollbar::-webkit-scrollbar-track {
    background: none;
    border: none;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #dadce0;
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 8px;
    box-shadow: none;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .scale-down-leftmob {
    -webkit-animation: scale-down-left 3s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
            animation: scale-down-left 3s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
  }
  .scale-down-left {
    -webkit-animation: scale-down-left 3s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
            animation: scale-down-left 3s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 270px;
    outline: none;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: red;
    border: 1px solid red;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #fbc086;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
}
.rotate-center {
	-webkit-animation: rotate-center 2s ease-in-out infinite both;
	        animation: rotate-center 2s ease-in-out infinite both;
}
.bounce-top {
	-webkit-animation: bounce-top 2s infinite both;
	        animation: bounce-top 2s infinite both;
}
.scale-down-tr {
	-webkit-animation: scale-down-tr 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-tr 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-3-13 12:21:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-down-tr
 * ----------------------------------------
 */
 @-webkit-keyframes scale-down-tr {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-down-tr {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-12 13:6:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-down-left
 * ----------------------------------------
 */
 @-webkit-keyframes scale-down-leftmob {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    left: 0;
    bottom: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes scale-down-leftmob {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
 @-webkit-keyframes scale-down-left {
  0% {
    left: 35%;
    bottom: 10%;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    left: 0;
    bottom: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes scale-down-left {
  0% {
    left: 35%;
    bottom: 10%;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    left: 0;
    bottom: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2023-3-13 10:6:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2023-3-13 11:5:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
